"use strict";
exports.__esModule = true;
exports.isCropped = exports.hasBlur = void 0;
function hasBlur(step) {
    var _a, _b;
    return (!!((_a = step.imageEditorState) === null || _a === void 0 ? void 0 : _a.redaction) &&
        ((_b = step.imageEditorState) === null || _b === void 0 ? void 0 : _b.redaction.length) > 0);
}
exports.hasBlur = hasBlur;
function isCropped(step) {
    var _a, _b, _c;
    if (!((_a = step.imageEditorState) === null || _a === void 0 ? void 0 : _a.crop) || !((_b = step.size) === null || _b === void 0 ? void 0 : _b.height) || !((_c = step.size) === null || _c === void 0 ? void 0 : _c.width)) {
        return false;
    }
    return (step.imageEditorState.crop.height !== step.size.height ||
        step.imageEditorState.crop.width !== step.size.width ||
        step.imageEditorState.crop.x !== 0 ||
        step.imageEditorState.crop.y !== 0);
}
exports.isCropped = isCropped;
