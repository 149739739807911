"use strict";
exports.__esModule = true;
exports.cn = void 0;
var clsx_1 = require("clsx");
var tailwind_merge_1 = require("tailwind-merge");
/**
 * A wrapper around `classNames` that merges the result with tailwind-merge.
 * This allows us to use tailwind classes in combination with classNames.
 * In particular, this allows us to use classes that target the same CSS property
 * in a conditional manner.
 * e.g. `cn('text-gray-600', { 'text-red-600': condition })` will result in
 * `text-red-600` if `condition` is true, and `text-gray-600` otherwise.
 */
function cn() {
    var inputs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        inputs[_i] = arguments[_i];
    }
    return (0, tailwind_merge_1.twMerge)((0, clsx_1.clsx)(inputs));
}
exports.cn = cn;
